import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import { media } from '../theme'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      metaTitle
      description
      image {
        asset {
          _ref: _id
          localFile {
            publicURL
          }
        }
      }
      _rawModules
    }
  }
`

const TitleModule = styled(Module).attrs(({ title, image }) => ({
  module: {
    _type: 'titleModule',
    variant: 'dark',
    title,
    image,
  },
}))`
  h1 {
    font-size: ${rem(38)};
    margin: ${rem(80)} 0 ${rem(12)};
    ${media('sm')`
      margin: ${rem(160)} 0 ${rem(32)};
    `}
  }
`

const IndexPage = ({ data: { page }, location: { pathname: path } }) => (
  <Layout>
    <SEO
      title={page.metaTitle || page.title}
      description={page.description}
      image={page.image ? page.image.asset.localFile.publicURL : null}
      path={path}
    />
    <TitleModule title={page.title} image={page.image} />
    {page._rawModules &&
      page._rawModules.map((module, idx) => (
        <Module key={module._key} module={module} />
      ))}
  </Layout>
)

export default IndexPage
